<template>
    <main>
      <div
        class="modal fade"
        id="modalPartesFisicas"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Registro de Partes de sistemas
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="card-waves ">
                <div class="card-header bg-light text-dark"><i class="fa fa-book fa-fw text-primary"></i>Datos</div>
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                      <label class="form-label">Nombre</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="partes.nombre"
                        :class="{
                          'is-invalid':
                            $v.partes.nombre.$error && $v.partes.nombre.$dirty,
                        }"
                      />
                    </div>
                  
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                      <label class="form-label">Sexo</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="partes.sexo"
                        :class="{
                          'is-invalid':
                            $v.partes.sexo.$error && $v.partes.sexo.$dirty,
                        }"
                      >
                        <option value="A">A</option>
                        <option value="F">F</option>
                        <option value="M">M</option>
                      </select>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                      <label class="form-label">Orden</label>
                      <input
                        class="form-control form-control-sm"
                        v-model="partes.orden"
                        type="number"
                        :class="{
                          'is-invalid':
                            $v.partes.orden.$error && $v.partes.orden.$dirty,
                        }"
                      >
                    </div>
                    
                  </div>
                </div>
              </div>
              
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="save()"
              >
                Guardar<i class="fa fa-save fa-fw"></i>
              </button>
              <button
                type="button"
                class="btn btn-light btn-sm"
                @click="cerrar()"
              >
                Cerrar<i class="fa fa-times-circle fa-fw"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  <script>
  
  import partesFisicasService from "@/services/partesFisicasService";
  import Toast from "../../../../components/common/utilities/toast";
  import { required } from "vuelidate/lib/validators";
  import $ from "jquery";
  
  export default {
    data() {
      return {
        partes: {
          id: "",
          nombre: "",
          sexo: "",
          orden: "",
        },
        
        type: "store",
      };
    },
    validations() {
      return {
        partes: {
          nombre: {required},
          sexo: {required},
          orden: {required}
        },
        
      };
    },
    methods: {
      
      async save() {
        try {
          this.$v.partes.$touch();
           
          if (this.$v.partes.$invalid) {
            return;
          }

          this.LoaderSpinnerShow();

          if (this.type === "store") {
            await partesFisicasService.store(this.partes);
            this.type = "store";
            this.limpiarCampos();
          } else if (this.type === "edit") {
            await partesFisicasService.update(this.partes);
            this.limpiarCampos();
            this.type = "store";
          }
  
          this.LoaderSpinnerHide();
  
          Toast.fire({
            icon: "success",
            title: "Datos guardados con exito",
          });

          this.$emit("cargar-partes");
  
          $("#modalPartesFisicas").modal("hide");
          
        } catch (err) {
          console.error(err);
          this.LoaderSpinnerHide();
          Toast.fire({
            icon: "error",
            title: "Ocurrio un error al procesar la solicitud",
          });
        }
      },
      async limpiarCampos() {
      this.partes.nombre = "";
      this.partes.sexo = "";
      this.partes.orden = "";

 
    },
      
      cerrar() {
        this.limpiarCampos();
        $("#modalPartesFisicas").modal("hide");
      },
      async edit(id) {
        this.$nextTick(async () => {
          this.type = "edit";
          $("#modalPartesFisicas").modal("show");
          const response = await partesFisicasService.show(id);
          this.partes.id = response.data.id;
          this.partes.nombre = response.data.nombre;
          this.partes.sexo = response.data.sexo;
          this.partes.orden = response.data.orden;
          
        });
      },
    },
  };
  </script>
  